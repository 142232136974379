import React from 'react'
import PropTypes from 'prop-types'
import {kebabCase} from 'lodash'
import {Helmet} from 'react-helmet'
import {graphql, Link} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import {FiClock} from "react-icons/all";

export const BlogPostTemplate = ({
                                   content,
                                   contentComponent,
                                   description,
                                   slug,
                                   excerpt,
                                   timeToRead,
                                   featuredImage,
                                   category,
                                   tags,
                                   title,
                                   date,
                                   author,
                                   posts,
                                   helmet,
                                 }) => {
  const PostContent = contentComponent || Content
  const relatedArticles = posts && posts.edges && posts.edges.length ?
    posts.edges.filter(p => p.node.frontmatter.category === category && p.node.frontmatter.title !== title)
    : [];
  return (
    <section className="section">
      {helmet || ''}
      <div className="container blog-post-main">
        <ul className="taglist">
          {tags.map((tag) => (
            <li key={tag + `tag`}>
              <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
            </li>
          ))}
        </ul>
        <h1 className="title">
          {title}
        </h1>
        <p className="post-meta">
          By <span className={'post-meta-author'}> {author}</span>
          <span className={'post-meta-date'}><FiClock/> {date}</span>
          <span className={'post-meta-category'}>{timeToRead} min read</span>
        </p>
        <div className="sharethis-inline-share-buttons" data-image={`https://www.littlerassociates.com/${featuredImage.childImageSharp.fixed.src}`}/>
        <PostContent content={content} className={'content'}/>
        {tags && tags.length ? (
          <div>
            <h4>Tags</h4>
            <ul className="taglist">
              {tags.map((tag) => (
                <li key={tag + `tag`}>
                  <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {relatedArticles && relatedArticles.length ? (
          <div>
            <h4>Related Articles</h4>
            <ul className={'related-articles'}>
              {relatedArticles
                .map(p => <li key={p.node.frontmatter.title}>
                  <Link to={p.node.fields.slug}>{p.node.frontmatter.title}</Link>
                </li>)}
            </ul>
          </div>
        ) : null}
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({data}) => {
  const {post, posts} = data;
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        slug={post.fields.slug}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        category={post.frontmatter.category}
        author={post.frontmatter.author}
        date={post.frontmatter.date}
        posts={posts}
        timeToRead={post.timeToRead}
        featuredImage={post.frontmatter.featuredimage}
        excerpt={post.excerpt}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="og:image" content={`https://www.littlerassociates.com${post.frontmatter.ogimage.childImageSharp.fluid.src}`} />
            <meta property="og:url" content={`https://www.littlerassociates.com${post.fields.slug}`} />
            <meta name="description" content={`${post.excerpt}`}/>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    posts:allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
             fields {
                slug
             }
             frontmatter {
               title
               category
             }
            }
           }
          }
    post: markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 400)
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
        featuredimage {
                  childImageSharp {
                    fixed( height: 200) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
         ogimage: featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
        category
        tags
      }
    }
  }
`
